const luhnChecksum = (ssn) =>
  ssn
    .substring(2, 11)
    .split("")
    .map((n) => parseInt(n))
    .reduce((sum = 0, value, index) => {
      // Multiply every other value by 2, then sum the digit sum
      const x = value * (2 - (index % 2));
      return sum + (x % 10) + Math.floor(x / 10);
    }, 0);

const validateFinalSsnDigit = (ssn) =>
  ((10 - (luhnChecksum(ssn) % 10)) % 10).toString() === ssn[11];

export const isValidSsn = (input = "") => {
  if (!input) {
    return false;
  }

  const parsedInput = input.replace(/\D/g, "");
  return isValidSocialSecurityNumberPattern.test(input) && validateFinalSsnDigit(parsedInput);
};

export const isValidOrganizationNumberPattern = new RegExp(
  /^(16|18|19|20)?([\d]{10}|([\d]{6}-[\d]{4}))$/
);

export const isValidSocialSecurityNumberPattern = new RegExp(
  /^(19|20)?(\d{2}(?:0\d|1[012])(?:[0-26-8]\d|[39][01])-?\d{4})$/
);

export const isValidPhoneNumberPattern = new RegExp(
  /^((([+]|00)?46)\s?(7)|07)[02369](((\d(-|\s)*){7})|(((-|\s)*\d){7}))$/
);

export const isValidEmailPattern = new RegExp(/[\w\-._]+@[\w\-._]+\.\w{2,10}/);

export function validateNumberConfiguration(key, field) {
  if (key === "ssn") {
    return isValidSsn(field);
  } else if (key === "desiredDeliveryDate") {
    return true;
  } else {
    return validationMap[key].test(field);
  }
}

const validationMap = {
  msisdn: isValidPhoneNumberPattern, // TODO msisdn validation
  email: isValidEmailPattern,
  organizationNumber: isValidOrganizationNumberPattern,
};
