<template>
  <div>
    <OwnerInformation :type="transferType" />
    <template v-if="isPrivateNumberMigration">
      <div
        class="transfer__number-migration-configuration__head tc-gr__col--12 transfer__input-tooltip"
        t-id="transfer__e-signing-information"
      >
        <telia-text-input
          t-id="portin__private-ssn"
          key="portin__private-ssn"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.ssnLabel')"
          :placeholder="t('numberMigrationConfiguration.ssnPlaceholder')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          :error-message="
            showInvalidSsnError ? t('numberMigrationConfiguration.validations.ssn') : ''
          "
          @input="handlePrivateSsn($event.target.value)"
        />
        <telia-notification
          v-if="shouldDisplayIncorrectOwner"
          t-id="operator-info"
          variant="inline"
          status="error"
          heading-tag="h3"
          button-aria-label="Operator information"
        >
          <telia-p slot="heading">
            {{
              t("numberMigrationConfiguration.alerts.transfer.private.transferNumberNotOwnerHeader")
            }}
          </telia-p>
          <telia-p slot="content">{{
            t("numberMigrationConfiguration.alerts.transfer.private.transferNumberNotOwnerText")
          }}</telia-p>
        </telia-notification>
        <telia-text-input
          t-id="transfer__private-email"
          key="transfer__private-email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailLabel.private')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          type="email"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </div>
    </template>
    <template v-else>
      <telia-text-input
        t-id="portin__organization-number"
        key="portin__organization-number"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.orgNrLabel')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.organizationNumber"
        :pattern-error-message="t('numberMigrationConfiguration.validations.organizationNumber')"
        @input="handleOrganizationNumber($event.target.value)"
      />
      <telia-notification
        v-if="shouldDisplayIncorrectOwner"
        t-id="operator-info"
        variant="inline"
        status="error"
        heading-tag="h3"
        button-aria-label="Operator information"
      >
        <telia-p slot="heading">
          {{
            t("numberMigrationConfiguration.alerts.transfer.business.transferNumberNotOwnerHeader")
          }}
        </telia-p>
        <telia-p slot="content">{{
          t("numberMigrationConfiguration.alerts.transfer.business.transferNumberNotOwnerText")
        }}</telia-p>
      </telia-notification>
      <telia-text-input
        t-id="transfer__organization-ssn"
        key="transfer__organization-ssn"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.ssnOrganizationLabel')"
        :placeholder="t('numberMigrationConfiguration.ssnPlaceholder')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :error-message="
          showInvalidSsnError ? t('numberMigrationConfiguration.validations.ssn') : ''
        "
        @input="handleConfigurationUpdate('ssn', $event.target.value)"
      />
      <div class="transfer__email-signature transfer__input-tooltip">
        <telia-text-input
          t-id="transfer__organization-email"
          key="transfer__organization-email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailLabel.business')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          type="email"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </div>
    </template>
  </div>
</template>

<script>
import { checkOwner } from "../services/number-migration-service";
import OrganizationValidationMessage from "./organization-validation-message";
import Tooltip from "./tooltip.vue";
import OwnerInformation from "./owner-information.vue";
import {
  isValidEmailPattern,
  isValidOrganizationNumberPattern,
  isValidSocialSecurityNumberPattern,
  isValidSsn,
  validateNumberConfiguration,
} from "../utils/validations";
import { translateMixin } from "../locale";

export default {
  name: "transfer",
  mixins: [translateMixin],
  components: { OrganizationValidationMessage, Tooltip, OwnerInformation },
  props: {
    disableForm: { type: Boolean },
    isOrganization: { type: String },
    scopeId: { type: String },
    phoneNumber: { type: String },
  },
  data: () => ({
    error: {
      organizationSignatoryRequest: false,
    },
    validationPatterns: {
      email: isValidEmailPattern.source,
      organizationNumber: isValidOrganizationNumberPattern.source,
      ssn: isValidSocialSecurityNumberPattern.source,
    },
    transferConfiguration: {
      email: null,
      organizationNumber: null,
      ssn: null,
    },
    isCorrectOwner: false,
    hasValidatedOwner: false,
    lastValidatedSignatory: { ssn: null, organizationNumber: null },
    validSignatory: null,
    pendingSignatoryValidation: false,
  }),
  async mounted() {
    this.emitConfigurationState();
  },
  computed: {
    shouldDisplayIncorrectOwner() {
      return this.hasValidatedOwner && !this.isCorrectOwner;
    },
    hasOrganizationSignatoryRequestError() {
      return (
        this.error.organizationSignatoryRequest &&
        this.validSignatory == null &&
        !this.pendingSignatoryValidation
      );
    },
    isPrivateNumberMigration() {
      return !this.isOrganization;
    },
    transferType() {
      return this.isPrivateNumberMigration ? "PRIVATE" : "BUSINESS";
    },
    isValid() {
      if (this.isPrivateNumberMigration) {
        const { organizationNumber, ...privateConfig } = this.transferConfiguration;
        const privateValidation = {
          ssn: isValidSocialSecurityNumberPattern.source,
          email: isValidEmailPattern,
        };

        return Object.keys(privateValidation)
          .map((key) => validateNumberConfiguration(key, privateConfig[key]))
          .every(Boolean);
      } else {
        return (
          Object.keys(this.validationPatterns)
            .map((key) => validateNumberConfiguration(key, this.transferConfiguration[key]))
            .every(Boolean) && this.isCorrectOwner
        );
      }
    },
    showInvalidSsnError() {
      return this.transferConfiguration.ssn && !isValidSsn(this.transferConfiguration.ssn);
    },
  },
  methods: {
    emitConfigurationState() {
      this.$emit("configuration-state", {
        configuration: this.transferConfiguration,
        valid: this.isValid,
      });
    },
    handleConfigurationUpdate(key, value) {
      this.transferConfiguration[key] = value;
      this.emitConfigurationState();
    },
    handlePrivateSsn(value) {
      this.transferConfiguration.ssn = value;
      this.hasValidatedOwner = false;
      if (value.length > 11 && isValidSocialSecurityNumberPattern.test(value)) {
        this.isCorrectOwner = false;
        this.checkOwnerShip();
      }
    },
    handleOrganizationNumber(value) {
      this.transferConfiguration.organizationNumber = value;
      this.hasValidatedOwner = false;
      if (value.length > 9 && isValidOrganizationNumberPattern.test(value)) {
        this.isCorrectOwner = false;
        this.checkOwnerShip();
      }
    },
    async checkOwnerShip() {
      try {
        this.isCorrectOwner = await checkOwner(
          this.scopeId,
          this.phoneNumber,
          this.transferConfiguration.organizationNumber,
          this.transferConfiguration.ssn
        );
        this.hasValidatedOwner = true;
      } catch (error) {
        this.hasValidatedOwner = true;
        this.isCorrectOwner = false;
      }
    },
    emitSignatoryRequestError() {
      this.$emit("signatory-request-error", this.hasOrganizationSignatoryRequestError);
    },
  },
  watch: {
    hasOrganizationSignatoryRequestError: "emitSignatoryRequestError",
  },
};
</script>

<style lang="scss" scoped>
.transfer {
  &__input-tooltip {
    position: relative;
    &__tooltip {
      position: absolute;
      right: 1rem;
    }
  }
}
</style>
