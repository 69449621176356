import { corpProductOrder } from "@telia/b2b-rest-client";

export const getNumberMigrationInfoForMsisdn = async (c2bUid, msisdn, scopeId) => {
  return corpProductOrder.NumberMigrationControllerService.getNumberMigrationInfoFromSubscription(
    scopeId,
    c2bUid,
    msisdn
  );
};

export const getNumberMigrationInfoForMsisdnUsingTscid = async (msisdn, scopeId, tscid) => {
  return corpProductOrder.NumberMigrationControllerService.getNumberMigrationInfo(
    scopeId,
    tscid,
    msisdn
  );
};

export const validateOrganizationSignatory = async (organizationNumber, ownerSSN, scopeId) => {
  return corpProductOrder.NumberMigrationControllerService.validateOrganizationSignatory(scopeId, {
    ssn: ownerSSN,
    orgNumber: organizationNumber,
  });
};

export const checkOwner = async (scopeId, msisdn, organizationNumber, ssn) => {
  return corpProductOrder.NumberMigrationControllerService.checkOwnership(
    scopeId,
    msisdn,
    organizationNumber,
    ssn
  );
};
