<template>
  <div>
    <origin-selector
      class="number-migration-configuration__horizontal-items"
      @number-origin="handleOriginSelection"
    />
    <OwnerInformation :type="selectedMigrationOrigin" />
    <template v-if="isPrivateNumberMigration">
      <telia-text-input
        t-id="portin__private-ssn"
        key="portin__private-ssn"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.ssnLabel')"
        :placeholder="t('numberMigrationConfiguration.ssnPlaceholder')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :error-message="
          showInvalidSsnError ? t('numberMigrationConfiguration.validations.ssn') : ''
        "
        @input="handleConfigurationUpdate('ssn', $event.target.value)"
      />
      <div class="portin__email-signature portin__input-tooltip">
        <telia-text-input
          t-id="portin__private-email"
          key="portin__private-email"
          type="email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailLabel.private')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </div>
    </template>
    <template v-else>
      <telia-text-input
        t-id="portin__organization-number"
        key="portin__organization-number"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.orgNrLabel')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.organizationNumber"
        :pattern-error-message="t('numberMigrationConfiguration.validations.organizationNumber')"
        @input="handleConfigurationUpdate('organizationNumber', $event.target.value)"
      />
      <telia-text-input
        t-id="portin__organization-ssn"
        key="portin__organization-ssn"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.ssnOrganizationLabel')"
        :placeholder="t('numberMigrationConfiguration.ssnPlaceholder')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :error-message="showInvalidSsnError ? t('numberMigrationConfiguration.validations.ssn') : ''"
        @input="handleConfigurationUpdate('ssn', $event.target.value)"
      />
      <telia-text-input
        t-id="portin__private-email"
        key="portin__private-email"
        type="email"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.emailLabel.business')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.email"
        :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
        @input="handleConfigurationUpdate('email', $event.target.value)"
      />

      <template>
        <div class="portin__email-signature portin__input-tooltip">
          <div class="portin__input-tooltip__tooltip">
            <tooltip
              t-id="portin__organization-email-signature__tooltip"
              :textRight="{
                boxRight: '-70',
                arrowRight: '14',
              }"
            >
              <span slot="content">
                {{ t("numberMigrationConfiguration.emailSignatureTooltipContent") }}
              </span>
            </tooltip>
          </div>
        </div>
        <telia-text-input
          t-id="portin__organization-email"
          key="portin__organization-email"
          :disabled="disableForm"
          :label="t('numberMigrationConfiguration.emailOrganizationLabel')"
          required
          :required-error-message="t('numberMigrationConfiguration.validations.required')"
          type="email"
          :pattern="validationPatterns.email"
          :pattern-error-message="t('numberMigrationConfiguration.validations.email')"
          @input="handleConfigurationUpdate('email', $event.target.value)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import OrganizationValidationMessage from "./organization-validation-message";
import {
  isValidEmailPattern,
  isValidOrganizationNumberPattern,
  isValidSocialSecurityNumberPattern,
  isValidSsn,
  validateNumberConfiguration,
} from "../utils/validations";
import OriginSelector from "./origin-selector";
import { translateMixin } from "../locale";
import Tooltip from "./tooltip.vue";
import OwnerInformation from "./owner-information.vue";

export default {
  name: "portin",
  mixins: [translateMixin],
  components: { OrganizationValidationMessage, OriginSelector, Tooltip, OwnerInformation },
  props: {
    disableForm: { type: Boolean },
    scopeId: { type: String },
    holidays: { type: Array },
  },
  data: () => ({
    error: {
      organizationSignatoryRequest: false,
    },
    validationPatterns: {
      email: isValidEmailPattern.source,
      organizationNumber: isValidOrganizationNumberPattern.source,
      ssn: isValidSocialSecurityNumberPattern.source,
    },
    portinConfiguration: {
      email: null,
      organizationNumber: null,
      ssn: null,
    },
    selectedMigrationOrigin: "PRIVATE",
    pendingSignatoryValidation: false,
  }),

  computed: {
    hasOrganizationSignatoryRequestError() {
      return this.error.organizationSignatoryRequest && !this.pendingSignatoryValidation;
    },
    isPrivateNumberMigration() {
      return this.selectedMigrationOrigin === "PRIVATE";
    },
    isValid() {
      return Object.keys(this.validationPatterns)
        .map((key) => {
          if (key === "organizationNumber" && this.isPrivateNumberMigration) {
            return true;
          } else {
            return validateNumberConfiguration(key, this.portinConfiguration[key]);
          }
        })
        .every(Boolean);
    },
    showInvalidSsnError() {
      return this.portinConfiguration.ssn && !isValidSsn(this.portinConfiguration.ssn);
    },
  },
  methods: {
    emitConfigurationState() {
      this.$emit("configuration-state", {
        configuration: this.portinConfiguration,
        valid: this.isValid,
      });
    },
    handleOriginSelection(origin) {
      this.selectedMigrationOrigin = origin;
      this.emitNumberOrigin();
      this.resetForm();
    },
    handleConfigurationUpdate(key, value) {
      this.portinConfiguration[key] = value;
      this.emitConfigurationState();
    },
    emitSignatoryRequestError() {
      this.$emit("signatory-request-error", this.hasOrganizationSignatoryRequestError);
    },
    emitNumberOrigin() {
      this.$emit("number-origin", this.selectedMigrationOrigin);
    },
    resetForm() {
      this.portinConfiguration = { email: null, organizationNumber: null, ssn: null };
      this.emitConfigurationState();
    },
  },
  watch: {
    hasOrganizationSignatoryRequestError: "emitSignatoryRequestError",
  },
};
</script>
<style lang="scss" scoped>
.portin {
  &__input-tooltip {
    position: relative;
    &__tooltip {
      position: absolute;
      right: 1rem;
    }
  }
}
</style>
