<template>
  <section class="number-migration-configuration">
    <!--TODO: <section> elements should have aria-label or aria-labelledby -->
    <div class="number-migration-configuration__number-input-container">
      <telia-text-input
        t-id="number-migration-configuration__number-input"
        class="number-migration-configuration__number-input"
        :disabled="disableForm"
        :label="t('numberMigrationConfiguration.phoneNumber')"
        required
        :required-error-message="t('numberMigrationConfiguration.validations.required')"
        :pattern="validationPatterns.msisdn"
        :pattern-error-message="t('numberMigrationConfiguration.validations.phoneNumber')"
        @keydown.enter="getNumberMigrationInfoForMsisdn"
        @input="setMsisdnToMigrate"
      />
      <b2x-spinner
        v-if="pendingNumberMigrationInfo"
        class="number-migration-configuration__spinner"
        size="small"
      ></b2x-spinner>
    </div>
    <template
      v-if="error.numberMigrationInfo && !pendingNumberMigrationInfo"
      class="number-migration-configuration__error-message"
    >
      <message-presenter
        :messages="notificationMessages"
        :message-type="error.numberMigrationInfo"
        :additional-data="errorMessageData"
      />
    </template>
    <template v-if="validNumberForMigration">
      <telia-notification
        t-id="operator-info"
        variant="inline"
        status="information"
        heading-tag="h3"
        button-aria-label="Operator information"
      >
        <telia-p slot="heading" class="number-migration-configuration__notification-header">
          <b>
            {{
              typeOfNumberMigration === "PORT_IN"
                ? t("numberMigrationConfiguration.operatorMigrationInformation")
                : t("numberMigrationConfiguration.transferInformation")
            }}
          </b>
        </telia-p>
        <telia-p v-if="typeOfNumberMigration === 'PORT_IN'" slot="content">
          {{ t("numberMigrationConfiguration.operatorMigrationInformationBody") }}
        </telia-p>
      </telia-notification>
      <div class="number-migration-configuration__configuration-container">
        <MigrationDate
          v-if="showDateSelect"
          :holidays="holidays"
          :nextWorkday="nextWorkdayForOperation"
          @setDate="setDate"
        />
        <telia-fieldset variant="horizontal" legend="">
          <telia-heading
            v-if="typeOfNumberMigration === 'PORT_IN'"
            tag="h3"
            variant="subsection-100"
            class="number-migration-configuration__owner-legend"
            >{{ t("numberMigrationConfiguration.typeOfOwnerLegend") }}</telia-heading
          >
          <telia-slot-group>
            <div v-if="typeOfNumberMigration === 'PORT_IN'">
              <PortinNew
                v-if="noSignatoryEnabled"
                t-id="number-migration-configuration__portin"
                :disable-form="disableForm"
                :scope-id="scopeId"
                @configuration-state="handleConfigurationUpdate"
                @number-origin="handleNumberOrigin"
              />
              <Portin
                v-else
                t-id="number-migration-configuration__portin"
                :disable-form="disableForm"
                :scope-id="scopeId"
                @configuration-state="handleConfigurationUpdate"
                @signatory-request-error="handleSignatoryRequestError"
                @number-origin="handleNumberOrigin"
              />
            </div>
            <div v-else>
              <TransferNew
                v-if="noSignatoryEnabled"
                :disableForm="disableForm"
                :is-organization="organization"
                :scope-id="scopeId"
                :phoneNumber="numberMigrationConfiguration.msisdn"
                @configuration-state="handleConfigurationUpdate"
              />
              <Transfer
                v-else
                :disableForm="disableForm"
                :organization="organizationNumber"
                :scope-id="scopeId"
                @configuration-state="handleConfigurationUpdate"
                @signatory-request-error="handleSignatoryRequestError"
              />
            </div>
          </telia-slot-group>
        </telia-fieldset>
      </div>
      <div>
        <template
          v-if="showOrganizationSignatoryRequestError"
          class="number-migration-configuration__error-message"
        >
          <MessagePresenter
            :messages="notificationMessages"
            :message-type="'organizationSignatoryRequestError'"
          />
        </template>
      </div>
    </template>
  </section>
</template>
<script>
import { translateMixin, translateSetup } from "./locale";
import {
  getNumberMigrationInfoForMsisdn,
  getNumberMigrationInfoForMsisdnUsingTscid,
} from "./services/number-migration-service";
import { getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import msisdnMixin from "./utils/msisdn.mixin";
import { domEmit } from "./utils/customEmitHelper";
import { isValidPhoneNumberPattern } from "./utils/validations";
import { messages } from "./components/message-presenter/messages";
import MessagePresenter from "./components/message-presenter/message-presenter";
import { useFlag } from "@unleash/proxy-client-vue";
import Portin from "./components/portin";
import PortinNew from "./components/portin-new";
import Transfer from "./components/transfer";
import Tooltip from "./components/tooltip.vue";
import MigrationDate from "./components/migration-date";
import { defineComponent } from "vue";
import { holidays, nextAvailableWorkday } from "./services/workdayCalendarService";
import OwnerInformation from "./components/owner-information.vue";
import TransferNew from "./components/transfer-new.vue";
import moment from "moment";
import "@telia/b2x-spinner";

export default defineComponent({
  name: "NumberMigrationConfiguration",
  components: {
    MessagePresenter,
    Portin,
    Tooltip,
    Transfer,
    PortinNew,
    MigrationDate,
    OwnerInformation,
    TransferNew,
  },
  mixins: [msisdnMixin, translateMixin],
  props: {
    blockTransfer: {
      type: Boolean,
      default: false,
    },
    c2bUid: {
      type: String,
      required: false,
      default: "",
    },
    disableForm: {
      type: Boolean,
      required: false,
      default: false,
    },
    tscid: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup() {
    const noSignatoryEnabled = useFlag("b2b-number-migration-no-validation");
    const desiredDateEnabled = useFlag("b2b-mco-number-migration-date");
    const transferDateEnabled = useFlag("b2b-number-migration-transfer-move-date");
    return {
      noSignatoryEnabled,
      desiredDateEnabled,
      transferDateEnabled,
    };
  },
  data() {
    return {
      error: {
        numberMigrationInfo: false,
        organizationSignatoryRequest: false,
        numberMigrationErrorOrderNumber: null,
      },
      nameHidden: null,
      lastValidatedSignatory: {
        organizationNumber: null,
        ssn: null,
      },
      msisdnToMigrateIn: null,
      numberMigrationConfiguration: {
        type: null,
        email: null,
        msisdn: null,
        organizationNumber: null,
        ssn: null,
        c2bUid: null,
        simFormat: null,
        desiredDeliveryDate: null,
      },
      operatorName: null,
      organizationNumber: null,
      ownerFirstName: null,
      ownerLastName: null,
      pendingNumberMigrationInfo: null,
      pendingSignatoryValidation: null,
      scopeId: null,
      typeOfNumberMigration: null,
      validationPatterns: {
        msisdn: isValidPhoneNumberPattern.source,
      },
      validNumberForMigration: null,
      validSignatory: null,
      configurationValid: false,
      holidays: [],
      nextWorkday: null,
    };
  },
  async created() {
    translateSetup();
    this.scopeId = await getScopeIdOrThrow();
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;
    Promise.all([holidays(this.scopeId, currentYear), holidays(this.scopeId, nextYear)]).then(
      (responses) => {
        const holidaysForThisYearAndNext = [];
        holidaysForThisYearAndNext.push(...responses[0].holidays, ...responses[1].holidays);
        this.holidays = holidaysForThisYearAndNext;
      }
    );
    this.nextWorkday = (await nextAvailableWorkday(this.scopeId, 5)).workday;
  },
  computed: {
    showDateSelect() {
      if (this.typeOfNumberMigration === "PORT_IN") {
        return this.desiredDateEnabled;
      } else {
        return this.desiredDateEnabled && this.transferDateEnabled;
      }
    },
    nextWorkdayForOperation() {
      return this.typeOfNumberMigration === "PORT_IN"
        ? this.nextWorkday
        : moment().format("YYYY-MM-DD");
    },
    numberConfigurationIsValid() {
      return this.validNumberForMigration && this.configurationValid;
    },
    showOperatorAllowsDisclaimer() {
      return this.typeOfNumberMigration === "PORT_IN";
    },
    showTemporaryNumberInfo() {
      return this.typeOfNumberMigration === "PORT_IN" && !this.getNumberForMsisdn;
    },
    notificationMessages() {
      return messages;
    },
    disableOperatorButton() {
      return this.disableForm || !isValidPhoneNumberPattern.test(this.msisdnToMigrateIn);
    },
    displayablePhoneNumber() {
      return this.formatMsisdn(this.numberMigrationConfiguration.msisdn);
    },
    portinOngoingSameOrgError() {
      return this.error.numberMigrationInfo === "portinOngoingSameOrg";
    },
    showOrganizationSignatoryRequestError() {
      return this.error.organizationSignatoryRequest;
    },
    errorMessageData() {
      return this.portinOngoingSameOrgError
        ? { orderNumber: this.error.numberMigrationErrorOrderNumber }
        : {};
    },
    owner() {
      if (this.nameHidden) {
        return this.t("numberMigrationConfiguration.hiddenNumber");
      } else if (this.ownerLastName && this.ownerFirstName) {
        return `${this.ownerFirstName} ${this.ownerLastName}`;
      } else if (this.ownerLastName) {
        return this.ownerLastName;
      }
      return null;
    },
    getNumberForMsisdn() {
      return this.c2bUid;
    },
  },
  methods: {
    setDate(date) {
      this.numberMigrationConfiguration.desiredDeliveryDate = date;
      const configurationState = {
        configuration: this.numberMigrationConfiguration,
        valid: this.configurationValid && date,
      };
      this.handleConfigurationUpdate(configurationState);
    },
    handleSignatoryRequestError(hasError) {
      this.error.organizationSignatoryRequest = hasError;
    },
    getNumberMigrationInfoForMsisdn() {
      this.resetNumberMigrationConfiguration();
      this.validNumberForMigration = false;
      this.pendingNumberMigrationInfo = true;
      this.error.numberMigrationInfo = null;
      const msisdn = this.formatToMsisdn(this.msisdnToMigrateIn);

      let numberMigrationCall = null;

      if (this.getNumberForMsisdn) {
        numberMigrationCall = getNumberMigrationInfoForMsisdn(this.c2bUid, msisdn, this.scopeId);
      } else {
        numberMigrationCall = getNumberMigrationInfoForMsisdnUsingTscid(
          msisdn,
          this.scopeId,
          this.tscid
        );
      }

      numberMigrationCall
        .then((res) => {
          if (this.blockTransfer && res.type === "TRANSFER") {
            this.error.numberMigrationInfo = "notSupported";
          } else {
            this.validNumberForMigration = res.valid;
            this.typeOfNumberMigration = res.type;
            if (this.validNumberForMigration) {
              this.setOperatorInfo(res);
              this.setConfigurationValues(msisdn, res);
              if (res.type === "TRANSFER") {
                this.emitTransferTrackingData();
                this.numberMigrationConfiguration.desiredDeliveryDate =
                  this.desiredDateEnabled && this.transferDateEnabled
                    ? moment().format("YYYY-MM-DD")
                    : undefined;
              } else {
                this.numberMigrationConfiguration.desiredDeliveryDate = this.desiredDateEnabled
                  ? this.nextWorkday
                  : undefined;
              }
            } else if (res.errorCode) {
              this.setMessage(res.errorCode);
            }
            this.error.numberMigrationErrorOrderNumber = res.ongoingPortinOrderNumber;
          }
        })
        .catch((res) => {
          this.setMessage(res?.body?.translationKey);
        })
        .finally(() => {
          this.pendingNumberMigrationInfo = false;
          this.emitNumberMigrationConfiguration();
        });
    },
    setMsisdnToMigrate(event) {
      this.msisdnToMigrateIn = event.target.value;
      if (isValidPhoneNumberPattern.test(this.msisdnToMigrateIn)) {
        this.getNumberMigrationInfoForMsisdn(this.msisdnToMigrateIn);
      } else {
        this.resetNumberMigrationConfiguration();
        this.validNumberForMigration = false;
      }
    },
    handleConfigurationUpdate(configurationState) {
      this.configurationValid = configurationState.valid;
      Object.assign(this.numberMigrationConfiguration, configurationState.configuration);
      this.emitNumberMigrationConfiguration();
    },
    handleNumberOrigin(origin) {
      this.emitPortinTrackingData(origin);
    },
    emitNumberMigrationConfiguration() {
      domEmit(
        this.$el,
        "number-migration-configuration-change",
        this.numberConfigurationIsValid ? this.numberMigrationConfiguration : null
      );
    },
    emitPortinTrackingData(origin) {
      let trackingData = {
        type: "PORT_IN",
        origin: origin,
      };
      domEmit(this.$el, "number-migration-tracking-data", trackingData);
    },
    emitTransferTrackingData() {
      let origin = this.numberMigrationConfiguration.organizationNumber
        ? "ORGANIZATION"
        : "PRIVATE";

      let trackingData = {
        type: "TRANSFER",
        origin: origin,
      };

      domEmit(this.$el, "number-migration-tracking-data", trackingData);
    },
    resetNumberMigrationConfiguration(hardReset = true) {
      Object.keys(this.numberMigrationConfiguration).forEach((key) => {
        if (key === "msisdn") {
          this.numberMigrationConfiguration[key] = hardReset
            ? null
            : this.numberMigrationConfiguration[key];
        } else {
          this.numberMigrationConfiguration[key] = null;
        }
      });
      this.numberMigrationConfiguration.desiredDeliveryDate = this.desiredDateEnabled
        ? this.nextWorkday
        : undefined;
    },
    setConfigurationValues(msisdn, res) {
      this.numberMigrationConfiguration.msisdn = msisdn;
      this.numberMigrationConfiguration.type = res.type;
      this.organizationNumber = res.organizationNumber;
      this.organization = res.organisation;
      this.numberMigrationConfiguration.organizationNumber = this.noSignatoryEnabled
        ? undefined
        : res.organizationNumber;
      this.numberMigrationConfiguration.simFormat = res.simFormat;
      this.numberMigrationConfiguration.c2bUid = res.c2bUid || this.c2bUid;
    },
    setMessage(translationKey) {
      switch (translationKey) {
        case "PORT_IN_ONGOING":
          this.error.numberMigrationInfo = "portinOngoing";
          break;
        case "PORT_IN_ONGOING_FROM_SAME_ORG":
          this.error.numberMigrationInfo = "portinOngoingSameOrg";
          break;
        case "MIGRATION_NOT_POSSIBLE":
          this.error.numberMigrationInfo = "portinNotPossible";
          break;
        case "TRANSFER_INVALID":
          this.error.numberMigrationInfo = "transferNotPossible";
          break;
        case "SUBSCRIPTION_DATA_NOT_FOUND":
          this.error.numberMigrationInfo = "notSupported";
          break;
        default:
          this.error.numberMigrationInfo = this.getNumberForMsisdn
            ? "generalError"
            : "fetchingNumberError";
          break;
      }
    },
    setOperatorInfo(res) {
      this.operatorName = res.operatorName;
      this.operatorName =
        res.operatorName || this.t("numberMigrationConfiguration.defaultOperator");
      this.nameHidden = res.nameHidden;
      this.ownerFirstName = res.ownerFirstName;
      this.ownerLastName = res.ownerLastName;
    },
    async getHolidays(date) {
      this.holidays = (await holidays(this.scopeId, date.getYear())).holidays;
    },
  },
});
</script>
<style lang="scss" scoped>
@import "~@teliads/components/foundations/spacing/variables";
@import "~@teliads/components/foundations/colors/variables";

.number-migration-configuration {
  &__number-input-container {
    display: flex;
    align-items: flex-end;
    gap: $telia-spacing-8;
    margin-bottom: $telia-spacing-8;
    position: relative;
  }
  &__number-input {
    flex: 3 1 auto;
  }
  &__spinner {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
  &__horizontal-items {
    margin-bottom: $telia-spacing-24;
    > * + * {
      margin-left: $telia-spacing-12;
    }
  }

  &__error-message {
    margin: 1rem 0;
  }

  &__validate-signatory {
    &__spinner {
      display: flex;
      justify-content: center;

      b2x-spinner {
        height: 2rem;
        width: 2rem;
      }
    }
  }

  &__email-signature {
    display: flex;

    &__tooltip {
      margin-left: $telia-spacing-8;
      padding-top: 0.3rem;
    }
  }

  &__get-operator {
    display: flex;
    margin-bottom: $telia-spacing-24;

    &__tooltip {
      margin-left: $telia-spacing-2;
      margin-top: auto;
      margin-bottom: auto;
    }

    &__spinner {
      display: flex;
      justify-content: center;

      b2x-spinner {
        height: 2rem;
        width: 2rem;
      }
    }
  }
  &__configuration-container {
    margin: $telia-spacing-24 0;
  }
  &__owner-legend {
    margin-bottom: $telia-spacing-12;
  }
  &__notification-header {
    color: $telia-blue-600;
  }
}
</style>
